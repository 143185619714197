import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../public/img/aboutus_fd.png'
import _imports_1 from '../../../public/img/aboutus_ceftification.png'


const _hoisted_1 = { class: "bannrer-about" }
const _hoisted_2 = { class: "about-nav" }
const _hoisted_3 = { class: "bannrer-about" }
const _hoisted_4 = { class: "about-nav" }
const _hoisted_5 = {
  slot: "indicator",
  class: "custom-indicator",
  style: {"left":"16.6rem"}
}

import { ref, reactive } from "vue";
import Indicator from '../../components/home/Indicator.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'us',
  setup(__props) {


const currentIndex = ref(0)

const handleCarouselChange = (val: any) => {
  currentIndex.value = val
}

const carouselRef = ref(null)

const goToSlide = (index: number) => {
  currentIndex.value = index
  carouselRef.value.setActiveItem(index);
}


return (_ctx: any,_cache: any) => {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_carousel, {
      ref_key: "carouselRef",
      ref: carouselRef,
      height: "100vh",
      "show-arrow": false,
      "hover-stop": false,
      "show-indicators": false,
      "current-index": currentIndex.value,
      onChange: handleCarouselChange
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_carousel_item, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "banner-img",
              style: _normalizeStyle({ backgroundImage: `url(${require('../../../public/img/aboutus_bg.png')})` })
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "en-title" }, "ABOUT US", -1)),
                  _createElementVNode("div", {
                    class: "cn-title",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (goToSlide(0)), ["stop"]))
                  }, [
                    _cache[4] || (_cache[4] = _createTextVNode("关于我们 ")),
                    _createElementVNode("span", {
                      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (goToSlide(1)), ["stop"]))
                    }, "/权威认证")
                  ])
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("img", {
                  class: "about-img_nav",
                  style: {"margin-top":"1rem"},
                  src: _imports_0,
                  alt: ""
                }, null, -1))
              ])
            ], 4)
          ]),
          _: 1
        }),
        _createVNode(_component_el_carousel_item, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "banner-img",
              style: _normalizeStyle({ backgroundImage: `url(${require('../../../public/img/aboutus_bg.png')})` })
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "en-title" }, "AUTHORITATIVE CERTIFICATION", -1)),
                  _createElementVNode("div", {
                    class: "cn-title",
                    onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (goToSlide(1)), ["stop"]))
                  }, [
                    _cache[7] || (_cache[7] = _createTextVNode("权威认证 ")),
                    _createElementVNode("span", {
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (goToSlide(0)), ["stop"]))
                    }, "/关于我们")
                  ])
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("img", {
                  class: "about-img",
                  style: {"width":"55rem","margin-top":"1rem"},
                  src: _imports_1,
                  alt: ""
                }, null, -1))
              ])
            ], 4)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(Indicator, {
            codeNumber: 2,
            courserIndex: currentIndex.value,
            onSetActiveItem: goToSlide
          }, null, 8, ["courserIndex"])
        ])
      ]),
      _: 1
    }, 8, ["current-index"])
  ]))
}
}

})