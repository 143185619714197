import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../public/img/home_wechat.png'
import _imports_1 from '../../../public/img/home_phone.png'


const _hoisted_1 = { class: "footer-content" }
const _hoisted_2 = { style: {"display":"flex","margin-left":"-0.2rem"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "filling-div-box" }
const _hoisted_5 = {
  key: 0,
  class: "filling-mask"
}
const _hoisted_6 = {
  key: 1,
  class: "filling-div"
}
const _hoisted_7 = { class: "footer-bottom" }




export default /*@__PURE__*/_defineComponent({
  __name: 'Indicator',
  props: ['courserIndex', 'codeNumber'],
  emits: ['setActiveItem'],
  setup(__props, { emit: __emit }) {

const props = __props;
const $emit = __emit

const goToSlide = (index: number) => {
  $emit('setActiveItem', index)
};

const goWayToLink = () => {
  window.location.href =
    "https://work.weixin.qq.com/kfid/kfc2bc3c0f191885b31";
}

return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.codeNumber, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["custom-indicator-item", { active: index === __props.courserIndex }]),
          key: index,
          onClick: ($event: any) => (goToSlide(index))
        }, [
          _createElementVNode("div", null, _toDisplayString(`0` + Number(index + 1)), 1),
          _createElementVNode("div", _hoisted_4, [
            (index === __props.courserIndex)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5))
              : _createCommentVNode("", true),
            (index === __props.courserIndex)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6))
              : _createCommentVNode("", true)
          ])
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", {
        onClick: goWayToLink,
        class: "left"
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          style: {"width":"14%","margin":"0 0.8rem"},
          src: _imports_0,
          alt: ""
        }, null, -1),
        _createElementVNode("div", {
          class: "f-xian",
          style: {"width":"6rem"}
        }, "微信在线咨询", -1)
      ])),
      _createVNode(_component_el_popover, {
        placement: "top-start",
        width: "200",
        trigger: "hover"
      }, {
        reference: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "right" }, [
            _createElementVNode("div", { class: "right-mask" }),
            _createElementVNode("div", { class: "right-item" }, [
              _createElementVNode("img", {
                style: {"width":"14%","margin":"0 0.8rem"},
                src: _imports_1,
                alt: ""
              }),
              _createElementVNode("div", {
                class: "f-xian",
                style: {"width":"4rem"}
              }, "电话咨询")
            ])
          ], -1)
        ])),
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("div", null, [
            _createElementVNode("div", { style: {"font-size":"0.6rem","font-weight":"600"} }, "免费咨询电话"),
            _createElementVNode("div", { style: {"color":"#165fd8","font-weight":"600"} }, "400-088-0901")
          ], -1))
        ]),
        _: 1
      })
    ])
  ]))
}
}

})