import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../public/img/toplogo_bottom.png'
import _imports_1 from '../../../public/img/home_wechat_black.png'
import _imports_2 from '../../../public/img/home_phone.png'


const _hoisted_1 = { class: "marke-index" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "overlay" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "overlay6" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  class: "custom-indicator",
  style: {"left":"5.2rem"}
}
const _hoisted_13 = { class: "footer-bottom" }

import { ref, reactive } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const goWayToLink = () => {
  window.location.href = "https://work.weixin.qq.com/kfid/kfc2bc3c0f191885b31";
};

const activeIndex = ref(0); // 默认选中第一个 div
const activeIndex6 = ref(0); // 默认选中第一个 div
const list = reactive([
  "fendanyun_01",
  "bg2",
  "bg2",
  "bg4",
  "bg5",
  "bg6",
  "bg7",
]);
// const list = reactive([
//   "fendanyun_01",
//   "fendanyun_02",
//   "fendanyun_03",
//   "fendanyun_04",
//   "fendanyun_05",
//   "fendanyun_06",
//   "fendanyun_07",
// ]);
const initialDivs = ["div1", "div2s", "div3s", "div4s", "div5s", "div6s"];
const displayedDivs = ref(initialDivs);

// 特殊标题
const specialTitles = {
  1: "分蛋云获客erp，全新的业务增长模式",
  3: "数字化营销能力全景图",
  4: "营销获客整体链路——从潜客到成交",
  6: "您的数字化旅程，有我们始终如一的陪伴",
};

// 获取图片路径
const getImageUrl = (item) => {
  return require(`../../../public/img/${item}.jpg`);
};

// 获取特殊图片路径
const getSpecialImage = (index) => {
  return require(`../../../public/img/c${index + 1}.png`);
};
const navigateToHash = () => {
  window.location.href = "#";
};
const setActive = (index, boxItem) => {
  if (activeIndex.value === index) {
    return;
  }
  // 切换当前点击的 boxItem 的状态
  const updatedDivs = displayedDivs.value.map((item, i) => {
    if (i === index) {
      return item.endsWith("s") ? item.slice(0, -1) : item + "s"; // 切换状态
    }
    return item.endsWith("s") ? item : item + "s"; // 其他元素恢复为带 's' 后缀
  });

  displayedDivs.value = updatedDivs; // 更新显示的 div
  activeIndex.value = index; // 更新激活的 div 索引
};

const initialDiv6s = ["div6-1", "div6-2s", "div6-3s", "div6-4s"];
const displayedDiv6s = ref(initialDiv6s);

const setActive6 = (index, boxItem) => {
  if (activeIndex6.value === index) {
    return;
  }
  // 切换当前点击的 boxItem 的状态
  const updatedDiv6s = displayedDiv6s.value.map((item, i) => {
    if (i === index) {
      return item.endsWith("s") ? item.slice(0, -1) : item + "s"; // 切换状态
    }
    return item.endsWith("s") ? item : item + "s"; // 其他元素恢复为带 's' 后缀
  });

  displayedDiv6s.value = updatedDiv6s; // 更新显示的 div
  activeIndex6.value = index; // 更新激活的 div 索引
};

return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "home-nav" }, [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        })
      ])
    ], -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "image-container"
      }, [
        _createElementVNode("div", {
          class: "banner-img",
          style: _normalizeStyle({
          backgroundImage: `url(${getImageUrl(item)})`,
          height: index === 2 ? '118vh' : '100vh',
        })
        }, [
          (specialTitles[index])
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: "overlay-title",
                  style: _normalizeStyle({ top: index === 6 ? '5.7rem' : '2.3rem' })
                }, _toDisplayString(specialTitles[index]), 5),
                _createElementVNode("img", {
                  src: getSpecialImage(index),
                  alt: "Image",
                  class: "box-con-image",
                  style: _normalizeStyle({ top: index === 6 ? '9.7rem' : '7.3rem',
                    width: index === 6 ? '70rem' : '60rem'
            })
                }, null, 12, _hoisted_3),
                (index == 1)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      style: {"height":"3rem","width":"10rem","position":"absolute","left":"50%","transform":"translateX(-50%)","top":"30rem"},
                      onClick: navigateToHash
                    }))
                  : _createCommentVNode("", true),
                (index == 6)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      style: {"width":"11rem","position":"absolute","left":"11%","transform":"translateX(-11%)","top":"25.5rem"},
                      src: require(`../../../public/img/btn.png`),
                      onClick: goWayToLink
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (index === 2)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _cache[0] || (_cache[0] = _createElementVNode("div", {
                  class: "overlay-title",
                  style: {"top":"3.3rem"}
                }, "分蛋云营销体系", -1)),
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(displayedDivs.value, (boxItem, boxIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: boxIndex,
                      class: _normalizeClass(["box", { active: activeIndex.value === boxIndex }]),
                      onClick: ($event: any) => (setActive(boxIndex, boxItem)),
                      style: _normalizeStyle({ width: activeIndex.value === boxIndex ? '45.5%' : '20%' })
                    }, [
                      (activeIndex.value === boxIndex)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            onClick: goWayToLink,
                            style: {"z-index":"1","position":"absolute","bottom":"7.4rem","width":"15rem","height":"3rem","margin-left":"2rem"}
                          }))
                        : _createCommentVNode("", true),
                      _createElementVNode("img", {
                        src: require(`../../../public/img/${boxItem}.jpg`)
                      }, null, 8, _hoisted_7)
                    ], 14, _hoisted_6))
                  }), 128))
                ])
              ], 64))
            : _createCommentVNode("", true),
          (index === 5)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _cache[1] || (_cache[1] = _createElementVNode("div", {
                  class: "overlay-title",
                  style: {"top":"2.5rem"}
                }, " 聚焦行业，深耕更加垂直的细分行业 ", -1)),
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(displayedDiv6s.value, (boxItem, boxIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: boxIndex,
                      class: _normalizeClass(["box", { active: activeIndex6.value === boxIndex }]),
                      onClick: ($event: any) => (setActive6(boxIndex, boxItem)),
                      style: _normalizeStyle({ width: activeIndex6.value === boxIndex ? '37%' : '21%' })
                    }, [
                      _createElementVNode("img", {
                        src: require(`../../../public/img/${boxItem}.jpg`),
                        alt: "Image",
                        class: "box-image6"
                      }, null, 8, _hoisted_10)
                    ], 14, _hoisted_9))
                  }), 128))
                ]),
                _createElementVNode("img", {
                  style: {"width":"10rem","position":"absolute","left":"50%","transform":"translateX(-50%)","bottom":"3rem"},
                  src: require(`../../../public/img/btn.png`),
                  onClick: goWayToLink
                }, null, 8, _hoisted_11)
              ], 64))
            : _createCommentVNode("", true)
        ], 4)
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", {
          onClick: goWayToLink,
          class: "left"
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("img", {
            style: {"width":"14%","margin":"0 0.8rem"},
            src: _imports_1,
            alt: ""
          }, null, -1),
          _createElementVNode("div", {
            class: "f-xian",
            style: {"width":"6rem"}
          }, "微信在线咨询", -1)
        ])),
        _createVNode(_component_el_popover, {
          placement: "top-start",
          width: "200",
          trigger: "hover"
        }, {
          reference: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "right" }, [
              _createElementVNode("div", { class: "right-mask" }),
              _createElementVNode("div", { class: "right-item" }, [
                _createElementVNode("img", {
                  style: {"width":"14%","margin":"0 0.8rem"},
                  src: _imports_2,
                  alt: ""
                }),
                _createElementVNode("div", {
                  class: "f-xian",
                  style: {"width":"4rem","color":"#fff"}
                }, " 电话咨询 ")
              ])
            ], -1)
          ])),
          default: _withCtx(() => [
            _cache[4] || (_cache[4] = _createElementVNode("div", null, [
              _createElementVNode("div", { style: {"font-size":"0.6rem","font-weight":"600"} }, "免费咨询电话"),
              _createElementVNode("div", { style: {"color":"#165fd8","font-weight":"600"} }, "400-088-0901")
            ], -1))
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})