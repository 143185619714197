import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../../../public/img/toplogo_bottom.png'


const _hoisted_1 = { class: "nav-list" }
const _hoisted_2 = ["onClick"]

import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { reactive } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'Nav',
  setup(__props) {

const navList = reactive([
  // { name: '先用后付', href: '/' },
 
  // { name: '精选产品', href: '/selected/products' },
  { name: '营销获客', href: '/' },
  // { name: '营销获客', href: '/marketing/visitor' },
  { name: '解决方案', href: '/solve/programme' },
  { name: '精选产品', href: '/selected/products' },
  { name: '关于我们', href: '/about/us' }
])

const flag = ref(0)

const router = useRouter();
const route = useRoute();

const scrollPosition = ref(0);
const isShow = ref(null)
onMounted(() => {
  // 获取滚动条的位置并更新变量的值
  scrollPosition.value = window.scrollY;

  // 监听滚动事件，更新滚动条位置
  window.addEventListener('scroll', () => {
    scrollPosition.value = window.scrollY;
    if (scrollPosition.value > 80) {
      isShow.value = true
    } else {
      isShow.value = false
    }

  });
});

// 检测当前的router 是否显示为当前下标
const setNative = () => {
  const idx = navList.findIndex(item => item.href === route.path)
  flag.value = idx
}

setNative()

const navigateToTargetRoute = (item: string, index: number) => {
  flag.value = index
  router.push({ path: item })
  window.scrollTo(0, 0);
};



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["home-nav", isShow.value ? 'active' : ''])
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("div", null, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["nav-item", flag.value === index? 'active' : '']),
          key: index,
          onClick: ($event: any) => (navigateToTargetRoute(item.href, index))
        }, [
          _createTextVNode(_toDisplayString(item.name) + " ", 1),
          _createElementVNode("span", {
            class: _normalizeClass(["iconfont", flag.value !== index? 'iconfont-active' : ''])
          }, null, 2)
        ], 10, _hoisted_2))
      }), 128))
    ])
  ], 2))
}
}

})