import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  slot: "indicator",
  class: "custom-indicator",
  style: {"left":"16.6rem"}
}

import { ref, reactive } from "vue";
import Indicator from '../../components/home/Indicator.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'products',
  setup(__props) {

const list = reactive(['images_homebanner_4-1'])

const currentIndex = ref(0)

const handleCarouselChange = (val: any) => {
  currentIndex.value = val
}

const carouselRef = ref(null)

const goToSlide = (index: number) => {
  currentIndex.value = index
  carouselRef.value.setActiveItem(index);
}


return (_ctx: any,_cache: any) => {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_carousel, {
      ref_key: "carouselRef",
      ref: carouselRef,
      height: "100vh",
      "show-arrow": false,
      "hover-stop": false,
      "show-indicators": false,
      "current-index": currentIndex.value,
      onChange: handleCarouselChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_carousel_item, { key: index }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "banner-img",
                style: _normalizeStyle({ backgroundImage: `url(${require(`../../../public/img/${item}.png`)})` })
              }, null, 4)
            ]),
            _: 2
          }, 1024))
        }), 128)),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(Indicator, {
            codeNumber: 0,
            courserIndex: currentIndex.value,
            onSetActiveItem: goToSlide
          }, null, 8, ["courserIndex"])
        ])
      ]),
      _: 1
    }, 8, ["current-index"])
  ]))
}
}

})