
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";
import Nav from "@/components/home/Nav.vue";
// import Footer from "@/components/home/Footer.vue";
import Footer from "@/components/home/newFooter.vue";


export default defineComponent({
  components: {
    Nav,
    Footer,
  },
  setup() {
    const route = useRoute();

    // 计算属性，判断是否为 '/' 路由
    const showNav = computed(() => route.path !== "/");

    return {
      showNav,
    };
  }
});
